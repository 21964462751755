import {
  Filter,
  GetProposalProductDrilldownResponse,
  ProductDrilldownProposal,
  ProductPage,
  ProductProposalV2,
  ProductSendingLocationsSkusPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  errorSnackBar,
  ExtendedCustomBaseQueryType,
} from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

import { addProductToFilters } from '../../../utils/features/products/addProductToFilters';
import { productDetailApi } from '../../services/edit-rebalancing/product/productDetail';
import { productsApi } from '../../services/edit-rebalancing/product/productsPaged';
import { AppDispatch } from '../../store';

export const updatedSharedProductDrilldownFields = (
  cachedData: ProductDrilldownProposal,
  updatedData: ProductDrilldownProposal,
) => {
  cachedData.enabled = updatedData.enabled;
  cachedData.to_location_all_trips_quantity =
    updatedData.to_location_all_trips_quantity;
  cachedData.to_location_all_trips_coverage =
    updatedData.to_location_all_trips_coverage;
  cachedData.to_location_target_coverage =
    updatedData.to_location_target_coverage;
  cachedData.user_quantity = updatedData.user_quantity;
};

export const updateProductDetailCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  fromLocationId,
  skuId,
  productId,
  filters,
  limit,
  sortBy,
  searchTerm,
  includeNilTransfers,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>;
  dispatch: AppDispatch;
  batchId: string;
  fromLocationId: string;
  skuId: string;
  productId: string;
  filters: Filter[];
  limit: number;
  sortBy: string;
  searchTerm: string;
  includeNilTransfers: boolean;
}) => {
  const response = await customBaseQuery({
    url: encodeUrl({
      url: `/v2/batch/{batchId}/proposal/products/{productId}/sending-locations-skus?limit={limit}&sort_by={sortBy}`,
      variables: {
        batchId,
        productId,
        cursor: '',
        limit: `${limit}`,
        sortBy,
      },
    }),
    method: 'POST',
    body: {
      filters: activeFiltersWithSendingLocation({
        activeFilters: filters,
        fromLocationId,
      }),
      include_nil_transfers: includeNilTransfers,
      location_search_term: searchTerm,
    },
  });

  if (response.error) {
    return { error: response.error };
  }

  const {
    data: { items: productProposalData },
  } = response;

  if (!productProposalData) {
    errorSnackBar(enqueueSnackbar);
    return { error: 'No locations found' };
  }

  const foundLocationSku = productProposalData.find(
    (item) => item.sku.sku_id === skuId,
  );

  dispatch(
    productDetailApi.util.updateQueryData(
      'getProductSendingLocationSkusPaged',
      {
        batchId,
        productId,
        filters,
        sortBy,
        cursor: null,
        limit,
        locationSearchTerm: searchTerm,
        includeNilTransfers,
      },
      (draft) => {
        const locationSku = draft?.items.find(
          (item) =>
            item.sku.sku_id === skuId &&
            item.location.location_id === fromLocationId,
        );

        if (locationSku && foundLocationSku) {
          locationSku.enabled = foundLocationSku.enabled;
          locationSku.sending_quantity = foundLocationSku.sending_quantity;
          locationSku.post_transfer_coverage_weeks =
            foundLocationSku.post_transfer_coverage_weeks;
          locationSku.target_coverage_weeks =
            foundLocationSku.target_coverage_weeks;
        }
      },
    ),
  );

  return { data: null, error: null } as any;
};

const updateFoundAggProductFields = (
  product: ProductProposalV2,
  foundProduct: ProductProposalV2,
) => {
  product.enabled = foundProduct.enabled;
  product.transfer_quantity = foundProduct.transfer_quantity;
  product.unique_trips = foundProduct.unique_trips;
  product.understock_units_solved = foundProduct.understock_units_solved;
  product.sales_uplift = foundProduct.sales_uplift;

  return product;
};

export const updateProductAggregateCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  productId,
  filters,
  cursor,
  limit,
  sortBy,
  searchTerm,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<ProductPage>;
  dispatch: AppDispatch;
  batchId: string;
  productId: string;
  filters: Filter[];
  cursor: string | null;
  limit: number;
  sortBy: string;
  searchTerm: string;
}) => {
  const response = await customBaseQuery({
    url: encodeUrl({
      url: `v2/batch/{batchId}/proposal/products?${
        cursor ? `cursor={cursor}&` : ''
      }limit={limit}&sort_by={sortBy}`,
      variables: {
        batchId,
        cursor: cursor || '',
        limit: `${limit}`,
        sortBy,
      },
    }),
    method: 'POST',
    body: {
      filters: addProductToFilters({
        activeFilters: filters,
        productId,
      }),
      product_search_term: '',
    },
  });

  if (response.error) {
    return { error: response.error };
  }

  const {
    data: { items: productsData },
  } = response;
  const foundProduct = productsData[0];

  if (!foundProduct) {
    errorSnackBar(enqueueSnackbar);
    return { error: 'No product found' };
  }

  const sharedProductAggDispatchProps = {
    batchId,
    sortBy,
    cursor,
  };

  dispatch(
    productsApi.util.updateQueryData(
      'getPagedProducts',
      {
        ...sharedProductAggDispatchProps,
        filters,
        limit,
        productSearchTerm: searchTerm,
      },
      (draft) => {
        const cachedProduct = draft.items.find(
          (item) => item.product.product_id === productId,
        );
        if (cachedProduct) {
          updateFoundAggProductFields(cachedProduct, foundProduct);
        } else {
          dispatch(
            productsApi.util.updateQueryData(
              'getPagedProducts',
              {
                ...sharedProductAggDispatchProps,
                filters: addProductToFilters({
                  activeFilters: filters,
                  productId,
                }),
                limit: 1,
                productSearchTerm: '',
              },
              (draft) => {
                const product = draft.items.find(
                  (item) => item.product.product_id === productId,
                );

                if (product) {
                  updateFoundAggProductFields(product, foundProduct);
                }
              },
            ),
          );
        }
      },
    ),
  );
  return null;
};

export const activeFiltersWithSendingLocation = ({
  activeFilters,
  fromLocationId,
}: {
  activeFilters: Filter[];
  fromLocationId: string;
}) =>
  activeFilters
    .filter(
      ({ dimension_id: dimensionId }) => dimensionId !== 'from_location_id',
    )
    .concat([
      {
        name: 'From location',
        dimension_title: 'from_location_id',
        dimension_id: 'from_location_id',
        options: [{ id: fromLocationId, title: fromLocationId }],
      },
    ]);

export const activeFiltersWithSendingAndReceivingLocation = ({
  activeFilters,
  fromLocationId,
  toLocationId,
}: {
  activeFilters: Filter[];
  fromLocationId: string;
  toLocationId: string;
}) =>
  activeFilters
    .filter(
      ({ dimension_id: dimensionId }) =>
        dimensionId !== 'from_location_id' && dimensionId !== 'to_location_id',
    )
    .concat([
      {
        name: 'From location',
        dimension_title: 'from_location_id',
        dimension_id: 'from_location_id',
        options: [{ id: fromLocationId, title: fromLocationId }],
      },
      {
        name: 'To location',
        dimension_title: 'to_location_id',
        dimension_id: 'to_location_id',
        options: [{ id: toLocationId, title: toLocationId }],
      },
    ]);

export const updateProductDetailDrilldownCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  filters,
  skuId,
  selectedProductId,
  fromLocationId,
  toLocationId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>;
  dispatch: AppDispatch;
  batchId: string;
  filters: Filter[];
  skuId: string;
  selectedProductId: string;
  fromLocationId: string;
  toLocationId?: string;
}) => {
  const activeFilters = toLocationId
    ? activeFiltersWithSendingAndReceivingLocation({
        activeFilters: filters,
        fromLocationId,
        toLocationId,
      })
    : activeFiltersWithSendingLocation({
        activeFilters: filters,
        fromLocationId,
      });

  const postResponse = await (
    customBaseQuery as ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>
  )({
    url: encodeUrl({
      url: `/batch/{batchId}/proposal/products/{productId}`,
      variables: {
        batchId,
        productId: selectedProductId,
      },
    }),
    method: 'POST',
    body: {
      filters: activeFilters,
    },
  });

  if (postResponse.error) {
    return { error: postResponse.error };
  }

  const { data: updatedProductDrilldownData } = postResponse;

  if (!updatedProductDrilldownData) return { error: true };

  dispatch(
    productDetailApi.util.updateQueryData(
      'getProductDetailDrilldown',
      {
        batchId,
        productId: selectedProductId,
        skuId,
        filters,
        fromLocationId,
      },
      (draft) => {
        updatedProductDrilldownData.proposals.forEach(
          (updatedProductLocationSku) => {
            const draftProductLocationSku = draft.find(
              (item) =>
                item.sku.sku_id === updatedProductLocationSku.sku.sku_id &&
                item.from_location.location_id ===
                  updatedProductLocationSku.from_location.location_id &&
                item.to_location.location_id ===
                  updatedProductLocationSku.to_location.location_id,
            );

            if (!draftProductLocationSku) return;

            updatedSharedProductDrilldownFields(
              draftProductLocationSku,
              updatedProductLocationSku,
            );
          },
        );
      },
    ),
  );
  return { data: null, error: null } as any;
};
