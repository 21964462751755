import { EditActiveResponse, Filter } from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  errorSnackBar,
  ExtendedCustomBaseQueryType,
} from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

const ERROR_MESSAGE = 'Not enough free stock to fulfil requested action';

export const handleToggleEnable = async ({
  customBaseQuery,
  body,
  batchId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<EditActiveResponse>;
  body: {
    enabled: boolean;
    filters: Filter[];
    product_id?: string;
    from_location_id?: string;
    to_location_id?: string;
    location_id?: string;
    sku_id?: string;
  };
  batchId: string;
}) => {
  const patchResponse = await customBaseQuery({
    url: encodeUrl({
      url: `batch/{batchId}/proposal/active`,
      variables: { batchId },
    }),
    method: 'PATCH',
    body,
    extraArgs: { hideSnackbar: true },
  });

  const { data, error } = patchResponse;

  if (error) {
    if (error.status === 400) {
      enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
    } else {
      errorSnackBar(enqueueSnackbar);
    }
  }

  return { data, error };
};
