import {
  Batch,
  BatchWithRebalanceNetwork,
  CreateBatchResponse,
  CreateBatchScheduleRequest,
  CreateBatchScheduleResponse,
  DeleteBatchScheduleResponse,
  ExportBatchResponse,
  ListBatchOverviewResponse,
  PostBatchSubmissionResponse,
  rebalancing__batch__batch__routes__CreateBatchRequest,
} from '@autone/openapi-rebalancing';
import { encodeUrl } from '@autone/ui';

import { rebalancingApi } from '..';

const batchesApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatch: builder.query<
      BatchWithRebalanceNetwork,
      { batchId: string; extraArg?: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
      }),
      providesTags: ['Batch'],
      // we destructure response.batches here to avoid having to do it in the component
      transformResponse: (response: { batch: BatchWithRebalanceNetwork }) =>
        response.batch,
    }),
    getBatches: builder.query<ListBatchOverviewResponse, void>({
      query: () => `batch/overview`,
      providesTags: ['Batches'],
    }),
    patchBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
        status?: Batch['status'];
        name?: Batch['name'];
      }
    >({
      invalidatesTags: ['Batch'],
      query: ({ batchId, status, name }) => {
        return {
          url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
          method: 'PATCH',
          body: {
            batch: {
              status,
              name,
            },
          },
        };
      },
      async onQueryStarted(
        { batchId, status, name },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          batchesApis.util.updateQueryData('getBatches', undefined, (draft) => {
            draft.batches.forEach((item) => {
              if (item.batch.id === batchId) {
                if (status) {
                  item.batch.status = status;
                }
                if (name) {
                  item.batch.name = name;
                }
              }
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformResponse: (response: { batch: Batch }) => response.batch,
    }),
    createBatch: builder.mutation<
      CreateBatchResponse,
      rebalancing__batch__batch__routes__CreateBatchRequest
    >({
      query(body) {
        return {
          url: '/batch',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['NetworkTrips'],
    }),
    createBatchSchedule: builder.mutation<
      CreateBatchScheduleResponse,
      CreateBatchScheduleRequest
    >({
      invalidatesTags: ['Batches'],
      query: (body) => ({
        url: `/batch/schedule`,
        method: 'POST',
        body,
        extraArgs: { hideSnackbar: true },
      }),
    }),
    exportBatch: builder.query<ExportBatchResponse, { batchId: string }>({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/export`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {},
      }),
    }),
    submitBatch: builder.mutation<
      PostBatchSubmissionResponse,
      { batchId: string; recipientIds: string[] | undefined }
    >({
      query: ({ batchId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteBatch: builder.mutation<
      void,
      {
        batchId: string;
        submissionId: string;
        recipientIds: string[] | undefined;
      }
    >({
      query: ({ batchId, submissionId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission/{submissionId}`,
          variables: { batchId, submissionId },
        }),
        method: 'DELETE',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteSchedule: builder.mutation<
      DeleteBatchScheduleResponse,
      { scheduleId: string }
    >({
      invalidatesTags: ['Batch', 'Batches'],
      query: ({ scheduleId }) => ({
        url: encodeUrl({
          url: `batch/schedule/{scheduleId}`,
          variables: { scheduleId },
        }),
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useDeleteBatchMutation,
  useSubmitBatchMutation,
  useCreateBatchMutation,
  useCreateBatchScheduleMutation,
  useLazyExportBatchQuery,
  useGetBatchQuery,
  useLazyGetBatchQuery,
  useGetBatchesQuery,
  usePatchBatchMutation,
  useDeleteScheduleMutation,
} = batchesApis;
