import {
  EditActiveResponse,
  Filter,
  ProductPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  ExtendedCustomBaseQueryType,
  QueryReturnValue,
} from '@autone/ui';

import { EDIT_REBALANCING_TAGS } from '../../../../constant';
import { updateProductAggregateCache } from '../../../helpers';
import { handleToggleEnable } from '../../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../../rebalancing';

export const productsApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagedProducts: builder.query<
      ProductPage,
      {
        batchId: string;
        filters: Filter[];
        productSearchTerm?: string;
        sortBy: string;
        cursor: string | null;
        limit: number;
      }
    >({
      providesTags: ['ProductsPaged'],
      query: ({
        batchId,
        filters,
        sortBy,
        cursor,
        limit,
        productSearchTerm,
      }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/proposal/products?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: { filters, product_search_term: productSearchTerm },
      }),
    }),
    patchPagedProductStatus: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        productId: string;
        filters: Filter[];
        enabled: boolean;
        sortBy: string;
        cursor: string | null;
        limit: number;
        productSearchTerm: string;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) => tag !== 'ProductsPaged',
      ),
      async queryFn(
        {
          batchId,
          productId,
          filters,
          enabled,
          sortBy,
          cursor,
          limit,
          productSearchTerm,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<ProductPage>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters,
            enabled,
            product_id: productId,
          },
        });

        if (error) {
          return { error } as any;
        }

        await updateProductAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductPage>,
          dispatch,
          batchId,
          productId,
          filters,
          cursor,
          limit,
          sortBy,
          searchTerm: productSearchTerm,
        });

        return { data: null, error: null } as any;
      },
    }),
  }),
});

export const {
  useGetPagedProductsQuery,
  useLazyGetPagedProductsQuery,
  usePatchPagedProductStatusMutation,
} = productsApi;
