export const EDIT_REBALANCING_TAGS: (
  | 'Kpi'
  | 'Bestsellers'
  | 'Products'
  | 'ProductsPaged'
  | 'ProductsDrilldown'
  | 'ProductDetailTable'
  | 'ProductDetailDrilldownTable'
  | 'Trips'
  | 'TripsPaged'
  | 'TripsDrilldown'
  | 'Locations'
  | 'LocationsPaged'
  | 'LocationsDrilldown'
  | 'LocationDetailPaged'
  | 'LocationDetailDrilldown'
  | 'ChildPeakView'
)[] = [
  'Kpi',
  'Bestsellers',
  'Products',
  'ProductsPaged',
  'ProductsDrilldown',
  'ProductDetailTable',
  'ProductDetailDrilldownTable',
  'Trips',
  'TripsPaged',
  'TripsDrilldown',
  'Locations',
  'LocationsPaged',
  'LocationsDrilldown',
  'LocationDetailPaged',
  'LocationDetailDrilldown',
  'ChildPeakView',
];
