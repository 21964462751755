import { Batch, ProductBestseller } from '@autone/openapi-rebalancing';
import { encodeUrl } from '@autone/ui';
import { orderBy } from 'lodash';

import { rebalancingApi } from '../rebalancing';

const bestSellersApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBestsellers: builder.query<
      ProductBestseller[],
      { batchId: Batch['id']; limit?: number }
    >({
      query: ({ batchId, limit = 5 }) =>
        encodeUrl({
          url: `batch/{batchId}/bestsellers?limit={limit}`,
          variables: { batchId, limit: `${limit}` },
        }),
      providesTags: ['Bestsellers'],
      transformResponse: (response: { bestsellers: ProductBestseller[] }) => {
        const filteredBestsellers = response.bestsellers.filter(
          ({ sales_uplift: salesUplift }) => {
            return salesUplift && salesUplift > 0;
          },
        );

        return orderBy(filteredBestsellers, (item) => item.sales_uplift, [
          'desc',
        ]);
      },
    }),
  }),
});

export const { useGetBestsellersQuery } = bestSellersApis;
